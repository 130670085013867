import React from 'react';

/**
 * ユーザ情報表示サンプル
 */
class Top extends React.Component {
  /**
   * 初期化
   * @param {req} props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * レンダリングマウント後実行
   */
  componentDidMount() {}

  /**
   * @return {JSX}
   */
  render() {
    return (
      <div className="wrapper">
        <div className="container">
          <h3 style={{color: '#2fa4e7'}}>プライバシーポリシー</h3>

          本プライバシーポリシーはメルチャ (以下「本サービス」といいます) に適用されます。<br/>
          <br/>
          ■広告IDの利用<br/>
          本サービスでは広告を表示する際に、固有のIDが利用されています。<br/>
          アプリから送信されるデータという点で、利用することをここに明記しています。<br/>
          <br/>
          この情報から個人が特定されることはありません。<br/>
          <br/>
          ■プロフィール情報<br/>
          本サービスの円滑利用やお客様間で円滑にコミュニケーションを行っていただく等の目的で、プロフィール情報（本サービス上での表示名、アイコン画像、性別、ステータスメッセージ等）をご登録いただく場合がございます。<br/>
          プロフィール情報には、電話番号や住所等、個人を特定できるような情報を入力しないようにしてください。<br/>
          <br/>
          ■アクセスログ<br/>
          お客様が本サービスを利用した際にIP アドレス、端末種類、ブラウザ言語等の情報が自動で生成、保存されます。<br/>
          また、これらの情報は利用者環境を分析し、より良いサービス提供のため、また正常なサービス提供を妨害する不正行為防止のために利用いたします。<br/>
          <br/>
          ■お問い合わせ<br/>
          メールアドレス、端末機種名、OS情報等を取得しております。<br/>
          これらの情報はお問い合わせに対する調査、返答、及びご本人確認等のために利用いたします。<br/>
        </div>
      </div>
    );
  }
}

export default Top;
