import React from 'react';

/**
 * ユーザ情報表示サンプル
 */
class Top extends React.Component {
  /**
   * 初期化
   * @param {req} props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * レンダリングマウント後実行
   */
  componentDidMount() {}

  /**
   * @return {JSX}
   */
  render() {
    return (
      <div className="wrapper">
        <div className="container">
          <h1 className="page-title">名前を設定すれば利用可能</h1>

          <div className="app_info_block">
            <div className="top_screen_image_block top_screen_image_block_right">
              <img src="/image/homepage/edit_profile_half.png" className="top_screen_image" alt="グルチャ画面" />
            </div>

            <div className="app_info_text">
              まずはあなたの名前を登録しましょう。<br/>
              メルチャ内でのみ使用する名前で、本名である必要はありません。
            </div>
          </div>

          <h1 className="page-title">新しい友達を作るきっかけをつぶやこう</h1>

          <div className="app_info_block">
            <div className="top_screen_image_block top_screen_image_block_right">
              <img src="/image/homepage/timeline_screen_half.png" className="top_screen_image" alt="タイムライン画面" />
            </div>

            <div className="app_info_text">
              タイムラインでは自由につぶやいて新しい友達を探すきっかけを作りましょう。<br/>
              ただし暴力的・静的な発言は投稿削除・アプリ使用禁止措置の対象となることがあるので気をつけましょう。
            </div>
          </div>

          <br/><br/>

          <h1 className="page-title">気になる部屋に入ってみよう</h1>

          <div className="app_info_block">
            <div className="top_screen_image_block top_screen_image_block_right">
              <img src="/image/homepage/chat_room_screen_half.png" className="top_screen_image" alt="広場一覧画面" />
            </div>

            <div className="app_info_text">
              広場ではいろいろな話題について話している人たちがいます。<br/>
              「5人参加中」のようなボタンをタップすると、部屋の中で会話している人の一覧が確認できます。
            </div>
          </div>

          <br/><br/>

          <h1 className="page-title">部屋に入ると会話が可能</h1>

          <div className="app_info_block">
            <div className="top_screen_image_block top_screen_image_block_right">
              <img src="/image/homepage/chat_message_screen_half.png" className="top_screen_image" alt="グルチャ画面" />
            </div>

            <div className="app_info_text">
              気になる部屋に入ったら、まずは挨拶をしてみましょう。<br/>
              会話が盛り上がるといろいろな話題が出てくるので、みんなとの会話を楽しみましょう。
            </div>
          </div>

          <br/><br/>

          <h1 className="page-title">自分で部屋を作ることも可能</h1>

          <div className="app_info_block">
            <div className="top_screen_image_block top_screen_image_block_right">
              <img src="/image/homepage/new_chat_room_half.png" className="top_screen_image" alt="グルチャ画面" />
            </div>

            <div className="app_info_text">
              新しい話題について話したい場合は、自分で部屋を作成しましょう。<br/>
              ユーザが作成した部屋は、発言が一定時間ないと消滅します。
            </div>
          </div>

          <br/><br/>

          <h1 className="page-title">個チャでは２人きりで</h1>

          <div className="app_info_block">
            <div className="top_screen_image_block top_screen_image_block_right">
              <img src="/image/homepage/inbox_screen_half.png" className="top_screen_image" alt="グルチャ画面" />
            </div>

            <div className="app_info_text">
              メールでは１対１の個チャによる会話が可能です。<br/>
              個チャの内容は他のユーザにみられることもなく、秘密の会話が可能です。
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Top;
