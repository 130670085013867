import React from 'react';

/**
 * ユーザ情報表示サンプル
 */
class Top extends React.Component {
  /**
   * 初期化
   * @param {req} props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * レンダリングマウント後実行
   */
  componentDidMount() {}

  /**
   * @return {JSX}
   */
  render() {
    return (
      <>
        <div className="navbar navbar-default navbar-fixed-top header-background">
          <div className="container header-background">
            <div className="navbar-header header-background">
              <a className="navbar-brand" href="/">
                <img src="/image/homepage/logo.png" alt="Mailcha logo" width="25" height="25"/>
                <div className="header-title">メルチャ</div>
              </a>
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div className="container">

            <div className="top_screen_block">
              <div className="top_screen_image_block top_screen_image_block_right">
                <img src="/image/homepage/chat_message_screen.png" className="top_screen_image" alt="グルチャ画面" />
              </div>


              <div className="top_screen_text_left">
                <div className="top_screen_text">
                  グルチャとメールのコミュニケーションアプリ

                  <br/><br/>

                  <div className="top_screen_text_mini">
                    完全無料で全ての機能を利用可能です。<br/>
                    アプリをダウンロードして名前を登録したら、すぐにサービスを利用可能です。<br/>
                    [<a href='/what'>➡︎詳しく見る︎</a>]
                  </div>
                </div>
              </div>
            </div>

            <div className="top_screen_block">
              <div className="top_screen_image_block">
                <img src="/image/homepage/chat_room_screen.png" className="top_screen_image" alt="広場一覧画面" />
              </div>

              <div className="top_screen_text_right">
                <div className="top_screen_text">
                  気になる部屋にいつでも出入り可能

                  <br/><br/>

                  <div className="top_screen_text_mini">
                    広場にはいくつもの部屋があり、いつでも入退室が可能です。<br/>
                    部屋への参加登録の必要もなく、気になる人とグルチャを楽しむことが可能です。
                  </div>
                </div>
              </div>
            </div>

            <div className="top_screen_block">
              <div className="top_screen_image_block top_screen_image_block_right">
                <img src="/image/homepage/inbox_screen.png" className="top_screen_image" alt="メール画面" />
              </div>

              <div className="top_screen_text_left">
                <div className="top_screen_text">
                  メールを使えば1対1でのチャットも可能

                  <br/><br/>

                  <div className="top_screen_text_mini">
                    メールでは誰にも邪魔されずに個人的な会話が可能です。<br/>
                    好きな人と２人だけの会話を楽しみましょう。
                  </div>
                </div>
              </div>
            </div>

            <div className="top_screen_block">
              <div className="top_screen_image_block">
                <img src="/image/homepage/timeline_screen.png" className="top_screen_image" alt="広場一覧画面" />
              </div>

              <div className="top_screen_text_right">
                <div className="top_screen_text">
                  タイムラインで「いま」をみんなに共有

                  <br/><br/>

                  <div className="top_screen_text_mini">
                    いま体験したことや感じたことをタイムラインに書き込みましょう。<br/>
                    そこからコミュニケーションのきっかけを探せるかもしれません。
                  </div>
                </div>
              </div>
            </div>

            <div className="twitter_block">
              <div style={{fontWeight: 600}}>
                公式twitter
              </div>
              <a className="twitter-timeline" data-width="70%" data-height="500" href="https://twitter.com/napp_tokyo">Tweets by napp_tokyo</a>
              <meta charSet="UTF-8" />
              <script async src="https://platform.twitter.com/widgets.js"></script>
            </div>

          </div>
        </div>

        <br/><br/><br/><br/>
        <footer>
          &nbsp;&nbsp;
          <a href="https://apple.co/2qm6U6t">
            <img src="/image/homepage/app_store.png" alt="Apple Store logo" className="apple_logo_max_size" width="35%" height="35%"/>
          </a>
          <a href="http://bit.ly/2NGiMsY">
            <img alt='Google Play で手に入れよう' src='https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png' className="google_logo_max_size" width="45%" height="45%" />
          </a>
        </footer>
      </>
    );
  }
}

export default Top;
