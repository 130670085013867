import React from 'react';

/**
 * ユーザ情報表示サンプル
 */
class Top extends React.Component {
  /**
   * 初期化
   * @param {req} props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * レンダリングマウント後実行
   */
  componentDidMount() {}

  /**
   * @return {JSX}
   */
  render() {
    return (
      <div className="wrapper">
        <div className="container">
          <h3 style={{color: '#2fa4e7'}}>簡略版</h3>

          ■メルチャはユーザ様間のコミュニケーションを行うサービスであり、下記のような行為を禁止します。<br/>
          <br/>
          ・他者を不快にさせる発言<br/>
          ・法に抵触するような発言<br/>
          ・あらゆる犯罪に加担する恐れのある発言<br/>
          <br/>
          その他迷惑行為、嫌がらせ行為、誹謗中傷行為等、他のお客さまに対する精神的被害・経済的被害を与える行為を行うユーザは、本アプリの利用禁止措置対象となります。<br/>
          <br/>
          ■メルチャのポイントは無料ポイントです。下記のようなサポートは行いません。<br/>
          <br/>
          ・ポイントの換金<br/>
          ・他者へのポイントの譲渡<br/>
          ・端末変更等によるポイントの移動<br/>
          <br/>
          またサービス内容向上のため、予告なく付与ポイント・消費ポイントの変更を行うことがあります。

          <hr/>

          <h3 style={{color: '#2fa4e7'}}>詳細版</h3>

          お客様が、本アプリ開発者（以下「当局」といいます）が配信・サービス提供する「メルチャ」（以下「本サービス」といいます）を利用するにあたっては、本利用契約の内容を承諾いただきます。<br/>
          <br/>
          (1)  本サービスは、ユーザ様間にてコミュニケーションを行う場を提供するサービスです。<br/>
          (2)  お客さまによる本サービスの利用開始をもって、お客さまと当局の間に本利用契約が成立するものとします。本利用契約に承諾いただけないお客さまは、直ちにご利用を中止し、また本サービスをご利用いただくためのアプリケーションのアンインストールを行うものとします。<br/>
          (3)  お客さまが未成年者である場合、親権者の同意を得た上で本サービスをご利用いただくものとします。<br/>
          (4)  ポイントの有効期間は、原則として最終ログインから半年間とします。<br/>
          (5)  お客さまは、不正な方法によりポイントを取得してはならないものとします。当局は、不正な方法によるポイントの取得であると判断した場合、当該ポイントの全部又は一部を失効させることができるものとします｡なお、当局の過誤によりポイントが付与された場合も同様とします。<br/>
          (6)  お客さまが携帯端末を変更された場合､保有していたポイントは変更後の端末に引継がれないものとします。当局は、当該ポイントが引き継がれないことについて何ら責任を負わないものとします。<br/>
          (7)  お客さまは、取得したポイントを他のお客さまとの間で共有、合算、贈与、貸借、質入又は譲渡することはできないものとします。<br/>
          (8)  お客さまが複数の携帯端末にて本サービスを利用してポイントを取得したとしても、これらのポイントを合算することはできないものとします。<br/>
          (9) 本サービスのご利用には通信料がかかります。<br/>
          (10) 本利用契約の締結及び解約は、全てお客さまが所有する携帯端末の操作によって行っていただくものとします。当局は、お客さまが所有する携帯端末で行われた操作を、全てお客さまの意思とみなします。<br/>
          (11) お客さまは本サービスを利用するにあたり、以下の行為を行ってはならないものとします。<br/>
          ①  本利用契約に違反する行為<br/>
          ②  虚偽の情報において、本サービスへの登録手続を行う行為<br/>
          ③  他のお客さまの個人情報を不当に収集、蓄積、利用する行為<br/>
          ④  迷惑行為、嫌がらせ行為、誹謗中傷行為等、他のお客さまに対する精神的被害・経済的被害を与える行為<br/>
          ⑤  当局、当局がライセンスを受けているライセンサー、他のお客さまもしくはその他の第三者の知的財産権、その他の財産権もしくは人格的な権利を侵害する行為、又はこれらを侵害するおそれのある行為<br/>
          ⑥  当局及び第三者の権利及び利益を侵害したり又はそのおそれがあるような行為<br/>
          ⑦  法令又は条例等に違反する行為<br/>
          ⑧  公序良俗に反する行為もしくはそのおそれのある行為、又は公序良俗に反するおそれのある情報を、他のお客さまもしくは第三者に提供する行為<br/>
          ⑨  犯罪行為、犯罪行為に結びつく行為もしくはこれを助長する行為、又はそのおそれがある行為<br/>
          ⑩  事実に反する情報又は事実に反するおそれのある情報を提供する行為<br/>
          ⑪  当局のシステムへの不正アクセス、コンピュータウィルスの頒布その他本サービスの正常な運営を妨げる行為、又はそのおそれのある行為<br/>
          ⑫  本サービスの信用を損なう行為、又はそのおそれのある行為<br/>
          ⑬  本サービスを利用した営利行為、又はその準備行為<br/>
          ⑭  当局もしくは当局の親会社、子会社、関連会社その他のグループ会社もしくはこれらの役員もしくは社員、他のお客さま、その他本サービスに関連する人物もしくは団体等を装い、又は身分を偽る行為、又はそのおそれがある行為<br/>
          ⑮  青少年の心身及びその健全な育成に悪影響を及ぼすおそれがある行為<br/>
          ⑯  異性との出会い等を希望、または誘導することを目的とする行為<br/>
          ⑰  その他、当局が不適切と判断する行為<br/>
          (12) お客さまが、本サービスに掲載されている情報及びこれに関わるコンテンツをご利用になる場合は、ご利用目的に応じてお客さまご自身のご判断と責任において行ってください。また、いかなる場合においても、お客さまが本サービスの情報またはコンテンツをご利用された結果発生した損害について、当局は一切の責任を負いません。<br/>
          (13) 当局は、本サービスに関し、次に掲げる情報（以下「お客さま情報」といいます）を取得することがあります。<br/>
          ①       お客さま個人に関する情報：お客さまのお名前、住所、メールアドレス、電話番号等<br/>
          ②       利用携帯端末に関する情報：使用デバイス、OS、端末言語設定<br/>
          ③       通信環境に関する情報：通信キャリア、通信環境、アクセス国・地域等<br/>
          ④       利用状況に関する情報：本サービスのバージョン、本サービスの利用履歴等<br/>
          (14) 当局は、お客さま情報を保存・蓄積した場合、善良な管理者の注意をもって取り扱うとともに、以下の目的で使用します。<br/>
          ①       本サービスの運営（当局からお客さまに対して、お問い合わせ対応、プッシュ通知、メールマガジンその他本サービス内案内情報の送信、キャンペーン等による当選者への賞品発送その他本サービス内で掲示する目的等であらゆる情報を提供することを含みます）<br/>
          ②       当局がお客さまにとって有益だと判断する当局のサービス（本サービスに限りません）又は広告主企業や提携先企業の商品、サービス等に関する情報の提供<br/>
          ③       お客さまに対する、本サービスの運営に著しく影響を及ぼす事柄（本サービス内容の大幅な変更、一時停止を含みますがこれらに限られません）に関する連絡<br/>
          ④       お客さまに対する、お客さま情報の取扱いに関する同意を求めるための連絡<br/>
          ⑤       本サービスの利用状況等に係る統計情報の作成、本サービス又は当局ウェブサイト等における公表及び第三者への販売その他の提供<br/>
          ⑥       お客さまの年齢、職業、性別、趣味・嗜好等に合わせた広告その他の情報の提供<br/>
          (15) 本サービスをご利用いただくためのアプリケーションをインストールするとUUIDが付与されます。UUIDとは、アプリケーションのインストール時に生成されるIDであり、携帯端末固有の情報であるUDIDと区別されるものです。当局は、UUIDをお客さまの本人確認及び本サービスの利便性向上等のために利用いたします。<br/>
          (16) 当局は、本サービスにおいて、他社の運営するサービス（以下「他社サービス」といいます）と連携したサービスも提供することがあります。他社サービスとの連携により、お客さまの選択した行為に関する情報が当該他社サービスにも共有されることがあります。そのため、お客さまは、当該他社サービスの利用条件等も確認した上で本サービスを利用するものとします。<br/>
          (17) お客さまが本利用契約に違反したと当局が判断した場合､お客さまにかかわる情報を当局及び当局グループ会社において共有し､当局及び当局グループ会社が提供する他のサービスにおいても同様の本利用契約違反があったものとみなすことができるものとします｡なお、この共有が行なわれたことによりお客さまに生じた不利益について､当局は責めを負わないものとします。<br/>
          (18) 当局は、裁判所、検察庁、警察等の公的機関からお客さまにかかわる情報の開示・提供を要求された場合には、お客さまの承諾を得ずにこれに応じる場合があります。<br/>
          (19) 本サービスに関する全ての情報、プログラム、商標、商号その他これらに付随する編集著作権等を含む著作権、商標権、その他知的財産権等の一切の権利は、当局又は著作権を有する第三者に帰属します。<br/>
          (20) お客さまが本サービスの内容を当局に無断で転載・複写・改変・公衆送信・蓄積、その他私的使用の範囲を越えて利用することを禁止します。<br/>
          (21) 本サービスにおいて、掲示板、書き込み等、お客さまが情報送信できるサービスが提供された場合、お客さまが本サービスを通じて送信した情報（以下「送信情報」といいます）は、当局に対して、複製・公衆送信（送信可能化を含みます）その他頒布等する無償且つ非独占的な権利を許諾したものとみなします。また、お客さまは、送信情報に関わる著作者人格権の主張をしないものとします。<br/>
          (22) お客さまは、以下各号に該当するような情報を送信してはならないものとします。送信情報が以下各号に該当すると当局が判断した場合、お客さまへの通知なく削除、変更いたします。ただし、当局は送信情報の削除及び変更の義務、並びにお客さまの行為を監視する義務を負うものではありません。<br/>
          ①       本サービスの趣旨に反する情報<br/>
          ②       自ら著作権等の知的財産権を有しない、又は権利者の許諾を得ていない表現を含む情報<br/>
          ③       本人が送信を許諾していない個人情報（複数の情報の組み合わせにより個人を特定できる情報を含みます）<br/>
          ④       公序良俗に反する表現を含む情報<br/>
          ⑤       政治・宗教・営業を目的とする表現を含む情報<br/>
          ⑥       第三者の権利を侵害する表現を含む情報<br/>
          ⑦       一般的に他のお客さまに不快となる表現を含む情報<br/>
          ⑧       犯罪予告など生命や財産に危険が及ぶおそれがある表現を含む情報<br/>
          ⑨       他のお客さまとの直接的な出会いを目的とする表現を含む情報<br/>
          ⑩       当局の本サービスの提供に妨げとなる表現を含む情報<br/>
          ⑪       その他当局が不適当と判断する表現を含む情報<br/>
          (23) 本サービスにおいて、賞品プレゼントキャンペーンが開催された場合、本利用契約のほか、当該キャンペーン要綱に従って行われるものとします。<br/>
          (24) 当局所定の方法により当選者として発表されたお客さまは、当局所定の手続きにより、当選者本人であることが確認された時点で当選確定となります。また、これに伴い賞品の発送先等当局の求める情報を通知するものとします。<br/>
          (25) 当選発表後10日以内に前項の手続きが終了しない場合、賞品受領までに本利用契約を解除していた場合、又は本利用契約に違反していた場合、当局は、当選者としての権利を喪失させることができるものとします。<br/>
          (26) 本サービスは、現状にて、完全性、正確性、有用性、信頼性等に瑕疵を含みうるものとして提供されます。当局は本サービスの提供及び内容について、その完全性・正確性・有用性・信頼性等に関するいかなる保証もいたしません。<br/>
          (27) 本サービスの提供に必要な設備の保守を定期的に又は緊急に行う場合、当該設備に障害が生じた場合、その他当局が必要と判断した場合、当局はお客さまへの予告なく、本サービスの提供を中断することができるものとします。<br/>
          (28) 当局は､本サービスを利用するために個々のお客さまが使用する機器､設備､ソフトウェア及び通信サービス等に関するサポートを行いません｡また､当局は､当局が管理する設備及びソフトウェアの設定等が､個々のお客さまが使用する機器､設備､ソフトウェアまたは通信サービス等に適合しない場合であっても､これらの変更､改変等を行う義務を負わないものとします｡<br/>
          (29) お客さまが本利用契約を解除される場合は、携帯端末を通じて解除の手続きを行なっていただきます。お客さまが本サービスに対応していない携帯端末に機種変更された後に本利用契約を解除される場合は、機種変更後の当該端末を通じて解除の手続きを行なっていただきます。<br/>
          (30) お客さまと電気通信事業者等の間の契約が終了した場合、当該契約の終了日をもって､本利用契約も解除されるものとします｡<br/>
          (31) 当局は、お客さまが本利用契約に違反した場合、お客さまに対する本サービスの提供を停止し、お客さまへ何らの通知、催告を要することなく本利用契約を解除することができるものとします。<br/>
          (32) 本サービスのご利用にあたり、お客さまに損害が発生した場合であっても､当局に故意又は重大な過失がない限り､当局は､間接損害､当局の予見の有無を問わず特別事情により発生又は拡大した損害及び逸失利益については責任を負いません｡<br/>
          (33) お客さまは、本利用契約に係るいかなる権利又は義務も第三者に移転又は譲渡することはできません。<br/>
          (34) 当局は本サービス及び本利用契約を予告なく改訂、追加、変更または廃止(以下合わせて「改定等」といいます)することができるものとします。また、改定等後の本利用契約については、本サービス上で告知されるものとし、改定等後に本サービスを利用し、または改訂後、解約手続きをとらずに2週間が経過した場合には、改訂等後の本利用契約に同意したものといたします。<br/>
          (35) 本利用契約の他､当局が定める諸規定がある場合にはそれぞれ本利用契約の一部を構成するものとします｡<br/>
          (36) 本利用契約は、その一部が消費者保護法令の強行規定部分又は他の強行規定により効力を有さないとされる場合でも、これに反しない最大限の範囲で効力を有するものとします。<br/>
          (37) 本サービス内では、本名・住所・電話番号・メールアドレス・マイナンバー等の個人情報にあたる情報は入力しないでください。<br/>
          (38) 本利用契約の解釈は日本法を準拠するものとします。本サービス又は本利用契約に関してお客さまとの間で疑義又は争いが生じた場合には、誠意を持って協議することとしますが、それでもなお解決しない場合には「東京地方裁判所」又は「東京簡易裁判所」を第一審の専属的合意管轄裁判所とします。<br/>
          ―以 上―<br/>
          <br/>
          2018/5/1<br/>

          <br/><br/><br/>
        </div>
      </div>
    );
  }
}

export default Top;
