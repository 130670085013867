import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Page from './page/index';

/**
 * React の Routing 設定
 */
class Routing extends React.Component {
  /**
   * React の Routing 設定
   * @return {Object} Routing 設定
   */
  render() {
    const router = createBrowserRouter([
      {path: '/', element: <Page.Top />},
      {path: '/what', element: <Page.What />},
      {path: '/web_view/term', element: <Page.WebView.Term />},
      {path: '/web_view/howto', element: <Page.WebView.Howto />},
      {path: '/web_view/privacy', element: <Page.WebView.Privacy />},
    ]);

    return (
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    );
  }
}

export default Routing;
