import React from 'react';

/**
 * ユーザ情報表示サンプル
 */
class Top extends React.Component {
  /**
   * 初期化
   * @param {req} props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * レンダリングマウント後実行
   */
  componentDidMount() {}

  /**
   * @return {JSX}
   */
  render() {
    return (
      <>
        <div className="navbar navbar-default navbar-fixed-top header-background">
          <div className="container header-background">
            <div className="navbar-header header-background">
              <a className="navbar-brand" href="/">
                <img src="/image/homepage/logo.png" alt="Mailcha logo" width="25" height="25"/>
                <div className="header-title">メルチャ</div>
              </a>
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div className="container">

            <h1 className="page-title">広場には沢山の部屋が</h1>

            <div className="app_info_block">
              <div className="top_screen_image_block top_screen_image_block_right">
                <img src="/image/homepage/chat_room_screen_half.png" className="top_screen_image" alt="広場一覧画面" />
              </div>

              <div className="app_info_text">
                広場にはいくつもの部屋があり、全ての部屋に自由に出入り可能です。<br/>
                部屋によっては人数制限が設定されており、入室できる最大人数がある部屋もあります。
              </div>
            </div>

            <h1 className="page-title">部屋に入ると会話が可能</h1>

            <div className="app_info_block">
              <div className="top_screen_image_block top_screen_image_block_right">
                <img src="/image/homepage/chat_message_screen_half.png" className="top_screen_image" alt="グルチャ画面" />
              </div>

              <div className="app_info_text">
                広場にはいくつもの部屋があり、全ての部屋に自由に出入り可能です。<br/>
                部屋によっては人数制限が設定されており、入室できる最大人数がある部屋もあります。
              </div>
            </div>

            <h1 className="page-title">個チャでは２人きりで</h1>

            <div className="app_info_block">
              <div className="top_screen_image_block top_screen_image_block_right">
                <img src="/image/homepage/inbox_screen_half.png" className="top_screen_image" alt="グルチャ画面" />
              </div>

              <div className="app_info_text">
                メールでは１対１の個チャによる会話が可能です。<br/>
                個チャの内容は他のユーザにみられることもなく、秘密の会話が可能です。
              </div>
            </div>

            <h1 className="page-title">名前を設定すれば利用可能</h1>

            <div className="app_info_block">
              <div className="top_screen_image_block top_screen_image_block_right">
                <img src="/image/homepage/edit_profile_half.png" className="top_screen_image" alt="グルチャ画面" />
              </div>

              <div className="app_info_text">
                アプリダウンロード後は、名前を登録すればすぐに利用可能です。<br/>
                面倒な会員登録などは必要ありません。
              </div>
            </div>

            <div className="note-block">
              ※ 本アプリはメルパカに似たアプリとなっておりますが、メルパカとの関連性はございません。
            </div>
          </div>
        </div>

        <br/><br/><br/><br/>
        <footer>
          &nbsp;&nbsp;
          <a href="https://apple.co/2qm6U6t">
            <img src="/image/homepage/app_store.png" alt="Apple Store logo" className="apple_logo_max_size" width="35%" height="35%"/>
          </a>
          <a href="http://bit.ly/2NGiMsY">
            <img alt='Google Play で手に入れよう' src='https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png' className="google_logo_max_size" width="45%" height="45%" />
          </a>
        </footer>
      </>
    );
  }
}

export default Top;
